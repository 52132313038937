import React from "react"

import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Hero from "../../components/Hero"
import Button from "../../components/ui/Button"
import Seo from "../../components/Seo"

export default function Page() {
  return (
    <Layout>
      <Seo title="Find a Builder" />
      <Hero
        content={
          <div className="flex flex-col justify-between h-full">
            <h1 className="mb-12 max-w-md !normal-case">
              Let's Find You a Builder
            </h1>
            <div className="space-y-4">
              <p>
                We're here to bring your project to life. <br />
                Choose below which best suits you or contact us directly.
              </p>
              <div className="grid grid-cols-1 sm:grid-cols-3 lg:w-[40rem] gap-x-5 gap-y-3">
                {/*<Button*/}
                {/*  to="/find-a-builder/new-build"*/}
                {/*  buttonStyle="tertiaryInverse"*/}
                {/*  expand*/}
                {/*>*/}
                {/*  New Build*/}
                {/*</Button>*/}
                <Button
                  to="/find-a-builder/contact-us"
                  buttonStyle="tertiaryInverse"
                  expand
                >
                  New Build
                </Button>
                {/*<Button*/}
                {/*  to="/find-a-builder/renovation"*/}
                {/*  buttonStyle="tertiaryInverse"*/}
                {/*  expand*/}
                {/*>*/}
                {/*  Renovate*/}
                {/*</Button>*/}
                <Button
                  to="/find-a-builder/contact-us"
                  buttonStyle="tertiaryInverse"
                  expand
                >
                  Renovate
                </Button>
                <Button
                  to="/find-a-builder/contact-us"
                  buttonStyle="secondaryOutlined"
                  expand
                >
                  Contact Us
                </Button>
              </div>
            </div>
          </div>
        }
        background={
          <StaticImage
            src="../../images/find-a-builder.jpeg"
            className="w-full h-full"
            quality={100}
            alt="An individual drawing on architectural planning paper."
          />
        }
        overlay="bg-opacity-40"
      />
    </Layout>
  )
}
